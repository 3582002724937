<template>
	<div class="navbar-container d-flex content align-items-center">
		<!-- Nav Menu Toggler UI RELATED CHANGES- -->
		<ul class="nav navbar-nav d-xl-none" v-if="!$route.meta.hideNavbar">
			<li class="nav-item">
				<b-link class="nav-link" @click="toggleVerticalMenuActive">
					<feather-icon icon="MenuIcon" size="21" />
				</b-link>
			</li>
		</ul>

		<b-navbar-nav class="nav align-items-center ml-auto">

			<site-dropdown />

			<dark-Toggler right class="d-sm-block" />
			<b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
				<template #button-content>
					<div class="d-sm-flex d-none user-nav">
						<p class="user-name font-weight-bolder mb-0">{{ getUserData.firstName }} {{ getUserData.lastName }}</p>
						<span class="user-status">Welcome</span>
					</div>
					<b-avatar size="36" :src="$root.getUploadFilePath(`user/${getUserData.photo_thumb}`)" variant="light-primary" badge class="badge-minimal" badge-variant="success">
						<feather-icon v-if="!getUserData.email" icon="UserIcon" size="22" />
					</b-avatar>
				</template>

				<b-dropdown-item :to="{ name: 'profile'}" link-class="d-flex align-items-center">
					<feather-icon size="16" icon="UserIcon" class="mr-50" />
					<span>Profile</span>
				</b-dropdown-item>

				<b-dropdown-divider />

				<b-dropdown-item link-class="d-flex align-items-center" @click="logout">
					<feather-icon size="16" icon="LogOutIcon" class="mr-50" />
					<span>Logout</span>
				</b-dropdown-item>
			</b-nav-item-dropdown>
		</b-navbar-nav>
	</div>
</template>

<script>
	import {
		BLink,
		BNavbarNav,
		BNavItemDropdown,
		BDropdownItem,
		BDropdownDivider,
		BAvatar,
		BDropdown,
		BImg,
		BNavItem
	} from "bootstrap-vue";
	import DarkToggler from "./nav-bar-items/DarkToggler.vue";
	import SiteDropdown from "./nav-bar-items/SiteDropdown.vue";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

	export default {
		components: {
			BLink,
			BNavbarNav,
			BNavItemDropdown,
			BDropdownItem,
			BDropdownDivider,
			BAvatar,
			BDropdown,
			BImg,
			BNavItem,

			// Navbar Components
			DarkToggler,
			SiteDropdown
		},
		props: {
			toggleVerticalMenuActive: {
				type: Function,
				default: () => { },
			},
		},
		data() {
			return {
				userData: this.$root.authUserObject,
			};
		},
		watch: {
			userData(newVal, OldVal) {
				return newVal;
			}
		},
		computed: {
			getUserData() {
				return this.userData;
			}
		},
		methods: {
			logout() {
				this.$authAPI.logout({
					'user_id': this.$root.authUserObject.id,
				}).then((response) => {
					response = response.data;
					if (response.isLogout) {
						window.AuthUserObject = false;
						window.AuthSiteObject = false;
						this.$root.authUserObject = [];
						this.$root.appSiteObject = [];
						this.$store.commit('appAuth/APP_LOGOUT');
						localStorage.removeItem('isAuthenticatedUser');
						this.$router.push('/login');
					}
				});
			},
		},
	};
</script>

<style>
	/* Media query for screen size 360x740 */
	@media screen and (min-width: 360px){
		[dir] .header-navbar .navbar-nav {
    		margin: -1px;
		}
		[dir=ltr] .header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-nav {
    		float: left;
    		margin-right: 0.8rem;
		}
		[dir] .header-navbar .navbar-container ul.navbar-nav li > a.nav-link {
    		padding: 0px 0.3rem;
		}
	}
	/* Styles specific to the 360x740 screen size */
  </style>