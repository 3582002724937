<template>
	<b-nav-item-dropdown id="dropdown-sitelist" class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right ref="dropdown">
		<template #button-content>
			<feather-icon class="text-warning" icon="GlobeIcon" size="20" />
			<span v-if="!isMobile()">
				<span class="m-25" href="#" v-if="$root.appSiteObject">{{ $root.appSiteObject.siteURL }}</span>
				<span class="m-25" href="#" v-else>No site seleted</span>
			</span>
			<span v-else>
				<span class="m-25" href="#" v-if="$root.appSiteObject">
					<span class="m-25" href="#" v-if="$root.appSiteObject.siteURL.length <= 14">{{ $root.appSiteObject.siteURL}}</span>
					<span class="m-25" href="#" v-if="$root.appSiteObject.siteURL.length > 14">{{ $root.appSiteObject.siteURL.substring(0,14)}}...</span>
				</span>
				<span class="m-25" href="#" v-else>No site seleted</span>
			</span>
			<feather-icon class="text-warning" icon="ChevronsDownIcon" size="20" />
		</template>

		<vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area show" tagname="li">
		<!-- Header -->
		<li class="dropdown-menu-header">
			<div class="dropdown-header d-flex">
				<b-row class="breadcrumbs-top">
					<b-col xl="12" sm="12">
						<h4 class="notification-title mb-0 mr-auto">
							<b-form-input id="boomark-search-input" v-model="searchQuery" placeholder="Search site" autocomplete="off" />
						</h4>
					</b-col>
					<b-col xl="12" sm="12">
						<h4 class="notification-title mb-0 mr-auto">
							<b-form-group class="">
								<b-form-radio-group v-model="sites_active_switch" :options="sites_active_options" class="demo-inline-spacing" name="sites_active_switch" @change="sitesActiveSwitch($event)" />
							</b-form-group>
						</h4>
					</b-col>
				</b-row>
			</div>
		</li>
		<!-- Body -->
			<b-link v-if="app_sites && app_sites.length" v-for="(site, index) in getAppSites" :key="index" @click="changeAppSite(site)">
				<b-media>
					<p class="media-heading">
						<span class="font-weight-bolder">
							{{ site.siteURL }}
						</span>
					</p>
				</b-media>
			</b-link>

		<!-- Cart Footer -->
		<li class="dropdown-menu-footer" v-if="!isMobile()">
			 <b-button variant="primary" block @click="addNewSiteFunction">Add New Site</b-button>
		</li>
		</vue-perfect-scrollbar>
	</b-nav-item-dropdown>
</template>

<script>
	import { BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox, BNavItem, BFormInput, BRow, BCol, BFormRadioGroup, BFormRadio, BFormGroup } from 'bootstrap-vue'
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'
	import Ripple from 'vue-ripple-directive'

	export default {
		components: {
			BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, VuePerfectScrollbar, BButton, BFormCheckbox, BNavItem, BFormInput, BRow, BCol, BFormRadioGroup, BFormRadio, BFormGroup
		},
		directives: {
			Ripple,
		},
		setup() {
			const perfectScrollbarSettings = {
				maxScrollbarLength: 60,
				wheelPropagation: false,
			}
			return {
				perfectScrollbarSettings
			}
		},
		props: {
			toggleVerticalMenuActive: {
				type: Function,
				default: () => { },
			},
		},
		data: function () {
			return {
				sites_active_options: [
					{ text: 'All sites', value: 0 },
					{ text: 'Active sites', value: 1 },
				],
				sites_active_switch: 1,
				searchQuery: '',
				app_sites: null,
				selectedSiteObject: null,
			};
		},
		methods: {
			isMobile() {
      			return /Android|iPhone/i.test(navigator.userAgent);
    		},
			changeAppSite(site) {
				this.$root.appSiteObject = site;
				this.selectedSiteObject = site;
				this.$siteAPI.setSession({
					'site_id': site.siteId,
				}).then((response) => {
					response = response.data;
					this.$refs.dropdown.hide(true); /* HIDE CURRENT OPEN DROPDOWN */
					this.$store.commit('app/TOGGLE_OVERLAY', false); /* FOR BLUR BACKEND */
					this.searchQuery = ''; /* EMPTY SEARCH BOX */
					this.$router.push("/summary").catch(() => { }); /* Refresh current page for set site to globle */
					EventBus.$emit('eventChangeAppSite', true); /* Fetch current site data */
				});
			},
			sitesActiveSwitch(event) {
				this.fetchSites();
			},
			fetchSites() {
				this.$siteAPI.get('list', {
					'status': this.sites_active_switch,
					'user_id': this.$root.authUserObject.id,
				}).then((response) => {
					this.app_sites = response.data.sites;
				});
			},
			addNewSiteFunction() {
				this.$refs.dropdown.hide(true); /* HIDE CURRENT OPEN DROPDOWN */
				this.$router.push("/add-new-site").catch(() => { }); /* Refresh current page for add new site */
			},
			clearSearchValue() {
				this.searchQuery = ''; // Clear the search value
			}
		},
		computed: {
			getAppSites: function () {
				let sortedSiteArray = _.sortBy(this.app_sites, "siteURL"); /* FOR SORT ARRAY TO A-Z */
				this.$store.commit('app/TOGGLE_OVERLAY', Boolean(this.searchQuery)); /* FOR BLUR BACKEND */
				return _.filter(sortedSiteArray, (item) => { /* FOR FILTER SITES WITH SEARCH */
					return item.siteURL.indexOf(this.searchQuery.toLowerCase()) > -1;
				});
			},
		},
		mounted() {
			// Clear search value when dropdown is closed
			this.$refs.dropdown.$on('hidden', this.clearSearchValue);
		},
		watch: {
			/* searchQuery(newVal, oldVal) {
				this.$store.commit('app/TOGGLE_OVERLAY', Boolean(newVal));
			} */
		},
		created: function () {
			this.fetchSites();
			EventBus.$on('onDeleteSiteChangeSiteEvent', (data) => {
				this.fetchSites();
			});
		},
	}
</script>

<style>
@media (max-width: 767px) {
    .header-navbar .navbar-container ul.navbar-nav li .media-list {
        max-height: 22rem;
    }
}
@media (min-width: 768px) {
    .header-navbar .navbar-container ul.navbar-nav li .media-list {
        max-height: 14rem;
    }
}
@media (min-width: 992px) {
    .header-navbar .navbar-container ul.navbar-nav li .media-list {
        max-height: 28rem;
    }
}
</style>